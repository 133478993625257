// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-button-overlayPanel .p-overlaypanel-content {
  padding: 10px;
}

.custom-radiobutton {
  align-items: center;
  display: flex;
  margin: 10px 0px;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/css/FilterButton.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,gBAAgB;EAChB,8BAA8B;AAChC","sourcesContent":[".filter-button-overlayPanel .p-overlaypanel-content {\n  padding: 10px;\n}\n\n.custom-radiobutton {\n  align-items: center;\n  display: flex;\n  margin: 10px 0px;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
