import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import "../../css/Table.css";
import InstantSearch from "../InstantSearch";
import FilterButton from "../FilterButton";

const Table = () => {
  const schools_backup = [
    {
      id: "1",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
      majors: [
        {
          id: "1",
          name: "Computer Science",
          title: "Undergraduate",
          status: "Active",
          time: "14/APR/2020",
          cost: "40000",
        },
        {
          id: "2",
          name: "Computer Science",
          title: "Undergraduate",
          status: "Overdue",
          time: "14/APR/2020",
          cost: "40000",
        },
        {
          id: "3",
          name: "Computer Science",
          title: "Undergraduate",
          status: "Overdue",
          time: "14/APR/2020",
          cost: "40000",
        },
      ],
    },
    {
      id: "2",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
      majors: [
        {
          id: "1",
          name: "Computer Science",
          title: "Undergraduate",
          status: "Active",
          time: "14/APR/2020",
          cost: "40000",
        },
        {
          id: "2",
          name: "Computer Science",
          title: "Undergraduate",
          status: "Overdue",
          time: "14/APR/2020",
          cost: "40000",
        },
        {
          id: "3",
          name: "Computer Science",
          title: "Undergraduate",
          status: "Overdue",
          time: "14/APR/2020",
          cost: "40000",
        },
      ],
    },
    {
      id: "3",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
      majors: [
        {
          id: "1",
          name: "Computer Science",
          title: "Undergraduate",
          status: "Active",
          time: "14/APR/2020",
          cost: "40000",
        },
        {
          id: "2",
          name: "Computer Science",
          title: "Undergraduate",
          status: "Overdue",
          time: "14/APR/2020",
          cost: "40000",
        },
        {
          id: "3",
          name: "Computer Science",
          title: "Undergraduate",
          status: "Overdue",
          time: "14/APR/2020",
          cost: "40000",
        },
      ],
    },
    {
      id: "4",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "5",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "6",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "7",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "8",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "9",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "10",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "11",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "12",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "13",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "14",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "15",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
    {
      id: "16",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      name: "University Of Arizona",
      location: "Tucson, Arizona United States",
      description:
        "Every year, thousands of students from more than 120 countries choose to earn their degrees at the University of Arizona's main campus in Tucson. With more than 300 majors, they have the academics and faculty to help you connect what you are learning with your aspirations to make the world better.",
    },
  ];

  const statusColorConfig = {
    Active: {
      text: "#3B82F6",
      background: "#E0E7FF",
      textDueOn: "#6B7280",
    },
    Overdue: {
      text: "#EF4444",
      background: "#FFE4E6",
      textDueOn: "#EF4444",
    },
  };
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [expandedRows, setExpandedRows] = useState({});
  const [schools, setSchools] = useState([]);
  useEffect(() => {
    setSchools(schools_backup);
  }, []);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('/content'); // Gọi API với fetch
  //       const data = await response.json(); // Chuyển đổi response thành JSON
  //       setSchools(data); // Cập nhật state với dữ liệu từ API
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchData(); // Gọi hàm fetch data
  // }, []); // Chỉ chạy 1 lần sau khi component mounted

  const onCustomPage = (event) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const toggleExpansion = (rowData, event) => {
    let _expandedRows = { ...expandedRows };

    if (_expandedRows[rowData.id]) {
      delete _expandedRows[rowData.id];
    } else {
      _expandedRows[rowData.id] = true;
    }
    setExpandedRows(_expandedRows);
    event.preventDefault(); // Ngăn sự kiện click mặc định gây ra reload trang
  };

  const expanderBodyTemplate = (rowData) => {
    const isExpanded = !!expandedRows[rowData.id];
    return (
      rowData.majors &&
      rowData.majors.length > 0 && (
        <div className="expander">
          <Button
            label={isExpanded ? "View Less" : "View More"}
            icon={isExpanded ? "pi pi-chevron-up" : "pi pi-chevron-down"}
            iconPos="right"
            className="p-button-link p-text-primary button-expander"
            onClick={(e) => toggleExpansion(rowData, e)}
          />
        </div>
      )
    );
  };

  const majorTemplate = (rowData) => {
    return (
      <div className="major-template">
        <div className="text-bold major-template-name">{rowData.name}</div>
        <div>{rowData.title}</div>
      </div>
    );
  };
  const statusTemplate = (rowData) => {
    return (
      <div>
        <div className="custom-status-tag">
          <span
            className="text-bold"
            style={{ background: statusColorConfig[rowData.status].background }}
          >
            <i
              className="pi pi-circle-fill"
              style={{
                color: statusColorConfig[rowData.status].text,
              }}
            ></i>
            <span
              style={{
                color: statusColorConfig[rowData.status].text,
                marginLeft: "5px",
              }}
            >
              <strong>{rowData.status}</strong>
            </span>
          </span>
        </div>
        <div style={{ color: statusColorConfig[rowData.status].textDueOn }}>
          <strong>Dued on: {rowData.time}</strong>
        </div>
      </div>
    );
  };
  const formatCurrency = (value) => {
    return Number(value).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  const costTemplate = (rowData) => {
    return (
      <div>
        USD <span className="text-bold">{formatCurrency(rowData.cost)}</span>
      </div>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div>
        <DataTable
          className="majors-expander-datatable"
          value={data.majors}
          responsiveLayout="scroll"
        >
          <Column style={{ width: "150px" }}></Column>
          <Column body={majorTemplate} align="center"></Column>
          <Column body={statusTemplate}></Column>
          <Column body={costTemplate} align="center"></Column>
        </DataTable>
      </div>
    );
  };

  const templatePaginator = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <React.Fragment>
          <span
            className="mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
          />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const SchoolNameTemplate = (rowData) => {
    return (
      <div>
        <div className="school-name">{rowData.name}</div>
        <div>
          <i className="pi pi-map-marker"></i>
          {rowData.location}
        </div>
      </div>
    );
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <img
        src={rowData.image}
        // onError={(e) =>
        //   (e.target.src =
        //     "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
        // }
        alt={rowData.image}
        className="shools-image"
      />
    );
  };

  return (
    <>
      <div className="filter-group">
        <div>
          <FilterButton />
        </div>
        <InstantSearch />
      </div>
      <DataTable
        value={schools}
        paginator
        paginatorTemplate={templatePaginator}
        first={first}
        rows={rows}
        onPage={onCustomPage}
        paginatorClassName="justify-content-end schools-paginator"
        className="mt-6 custom-table schools-table"
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        responsiveLayout="scroll"
        rowExpansionTemplate={rowExpansionTemplate}
        onRowDoubleClick={(e) =>
          (window.location.href = `http://localhost:3000/schools/${e.data.id}`)
        }
        dataKey="id"
      >
        <Column header="" body={imageBodyTemplate} />
        <Column
          field="name"
          header="Name"
          body={SchoolNameTemplate}
          sortable
          className="school-name-column"
        ></Column>
        <Column field="description" header="Description"></Column>
        <Column
          header={() => <i className="pi pi-ellipsis-h"></i>}
          headerStyle={{ textAlign: "center" }}
          alignHeader="center"
          expander
          body={expanderBodyTemplate}
          style={{ width: "3em" }}
        />
      </DataTable>
    </>
  );
};

export default Table;
