import { MultiSelect } from "primereact/multiselect";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import "../../css/SchoolLandingPage.css";
import SchoolSummary from "./SchoolSummary";
import { Card } from "primereact/card";

const SchoolsLandingPage = () => {
  const nations = [
    { value: "Canada", label: "Canada" },
    { value: "United States", label: "United States" },
    { value: "Mexico", label: "Mexico" },
    { value: "United Kingdom", label: "United Kingdom" },
    { value: "France", label: "France" },
    { value: "Germany", label: "Germany" },
    { value: "Italy", label: "Italy" },
    { value: "Australia", label: "Australia" },
    { value: "Japan", label: "Japan" },
    { value: "China", label: "China" },
    { value: "India", label: "India" },
    { value: "Vietnam", label: "Vietnam" },
    { value: "South Korea", label: "South Korea" },
  ];

  const eductionLevel = [
    { value: "Primary", label: "Primary" },
    { value: "Secondary", label: "Secondary" },
    { value: "Tertiary", label: "Tertiary" },
    { value: "University", label: "University" },
    { value: "Other", label: "Other" },
  ];

  const programs = [
    { value: "Computer Science", label: "Computer Science" },
    { value: "Engineering", label: "Engineering" },
    { value: "Business", label: "Business" },
    { value: "Medicine", label: "Medicine" },
    { value: "Other", label: "Other" },
  ];

  const fees = [
    { value: "0-10000", label: "0 - $10.000 / year" },
    { value: "10000-20000", label: "$10.000 - $20.000 / year" },
    { value: "20000-30000", label: "$20.000 - $30.000 / year" },
    { value: "30000-40000", label: "$30.000 - $40.000 / year" },
    { value: "40000-50000", label: "$40.000 - $50.000 / year" },
  ];

  const durations = [
    { value: "0-4", label: "0-4 years" },
    { value: "4-8", label: "4-8 years" },
    { value: "8-12", label: "8-12 years" },
  ];

  const programsLanding = [
    {
      id: "1",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      school_name: "University Of Arizona",
      school_location: "Tucson, Arizona United States",
      name: "Computer Science",
      duration: "3-Year Bachelor's Degree | 3 years",
      fee: "$12,000 / Year",
    },
    {
      id: "2",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      school_name: "University Of Arizona",
      school_location: "Tucson, Arizona United States",
      name: "Computer Science",
      duration: "3-Year Bachelor's Degree | 3 years",
      fee: "$12,000 / Year",
    },
    {
      id: "3",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      school_name: "University Of Arizona",
      school_location: "Tucson, Arizona United States",
      name: "Computer Science",
      duration: "3-Year Bachelor's Degree | 3 years",
      fee: "$12,000 / Year",
    },
    {
      id: "4",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      school_name: "University Of Arizona",
      school_location: "Tucson, Arizona United States",
      name: "Computer Science",
      duration: "3-Year Bachelor's Degree | 3 years",
      fee: "$12,000 / Year",
    },
    {
      id: "5",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      school_name: "University Of Arizona",
      school_location: "Tucson, Arizona United States",
      name: "Computer Science",
      duration: "3-Year Bachelor's Degree | 3 years",
      fee: "$12,000 / Year",
    },
    {
      id: "6",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      school_name: "University Of Arizona",
      school_location: "Tucson, Arizona United States",
      name: "Computer Science",
      duration: "3-Year Bachelor's Degree | 3 years",
      fee: "$12,000 / Year",
    },
    {
      id: "7",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      school_name: "University Of Arizona",
      school_location: "Tucson, Arizona United States",
      name: "Computer Science",
      duration: "3-Year Bachelor's Degree | 3 years",
      fee: "$12,000 / Year",
    },
    {
      id: "8",
      image:
        "https://photos.applyboard.com/schools/000/001/994/logos/small/Coventry_University_London_logo_Nov2021.png?1636498487",
      school_name: "University Of Arizona",
      school_location: "Tucson, Arizona United States",
      name: "Computer Science",
      duration: "3-Year Bachelor's Degree | 3 years",
      fee: "$12,000 / Year",
    },
  ];
  const [selectedNations, setSelectedNations] = useState([]);
  const [selectedEductionLevel, setSelectedEductionLevel] = useState([]);
  const [selectedPrograms, setSelectedPrograms] = useState([]);
  const [selectedFees, setSelectedFees] = useState([]);
  const [selectedDurations, setSelectedDurations] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const renderProgramCard = (program) => {
    return (
      <Card className="program-card">
        <div className="program-school-info">
          <img
            src={program.image}
            onError={(e) =>
              (e.target.src =
                "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt=""
            className="program-image"
          />
          <div>
            <div className="program-school-name">{program.school_name}</div>
            <div className="program-school-location">
              <i className="pi pi-map-marker"></i>
              {program.school_location}
            </div>
          </div>
        </div>
        <div>
          <div className="program-name">{program.name}</div>
          <div className="program-duration">{program.duration}</div>

          <div className="program-fee">{program.fee}</div>
        </div>
      </Card>
    );
  };

  return (
    <div>
      <div className="header">
        <img
          className="header-image"
          src="https://www.aaevietnam.com/upload/image/options/untitled-1.webp"
          onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
          }
          alt=""
        />
      </div>
      <div className="header-interview">
        <div className="header-interview-search-input">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="interview-search-input"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder="Search"
            />
          </span>
        </div>
      </div>
      <div className="container">
        <div className="landing-page-content">
          <div className="landing-page-filter p-grid">
            <div className="filter-component p-col-12 p-md-4 p-lg-2">
              <label>Nation</label>
              <MultiSelect
                value={selectedNations}
                options={nations}
                onChange={(e) => setSelectedNations(e.value)}
                optionLabel="label"
                placeholder="Select a Nation"
                maxSelectedLabels={2}
                filter
              />
            </div>
            <div className="filter-component p-col-12 p-md-4 p-lg-2">
              <label>Education Level</label>{" "}
              <MultiSelect
                className="p-fluid"
                value={selectedEductionLevel}
                options={eductionLevel}
                onChange={(e) => setSelectedEductionLevel(e.value)}
                optionLabel="label"
                placeholder="Select a Education Level"
                maxSelectedLabels={2}
                filter
              />
            </div>

            <div className="filter-component p-col-12 p-md-4 p-lg-2 filter-program">
              <label>Program</label>
              <MultiSelect
                className="p-fluid"
                value={selectedPrograms}
                options={programs}
                onChange={(e) => setSelectedPrograms(e.value)}
                optionLabel="label"
                placeholder="Select a Program"
                maxSelectedLabels={2}
                filter
              />
            </div>

            <div className="filter-component p-col-12 p-md-4 p-lg-2 filter-fee">
              <label>Fee</label>
              <MultiSelect
                className="p-fluid"
                value={selectedFees}
                options={fees}
                onChange={(e) => setSelectedFees(e.value)}
                optionLabel="label"
                placeholder="Select a Fee"
                maxSelectedLabels={2}
                filter
              />
            </div>

            <div className="filter-component p-col-12 p-md-4 p-lg-2 filter-duration">
              <label>Duration</label>
              <MultiSelect
                className="p-fluid"
                value={selectedDurations}
                options={durations}
                onChange={(e) => setSelectedDurations(e.value)}
                optionLabel="label"
                placeholder="Select a Duration"
                maxSelectedLabels={2}
                filter
              />
            </div>
          </div>
          <div className="p-grid landing-page-grid">
            <div className="p-col-12 p-lg-4 program-list-column">
              {programsLanding.map((pl) => {
                return renderProgramCard(pl);
              })}
            </div>
            <div
              className="p-col-12 p-lg-8 d-none d-lg-block"
              style={{ paddingLeft: "20px" }}
            >
              <SchoolSummary />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolsLandingPage;
