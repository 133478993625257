// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.instant-search-dialog .p-dialog-header,
.instant-search-dialog .p-dialog-content {
  padding: 0;
  overflow-x: hidden;
}

.instant-search-dialog-header {
  display: flex;
}

.instant-search-dialog-header .instant-search-input {
  width: 95%;
  border: none;
}

.instant-search-dialog-header .instant-search-input:focus {
  outline: none;
  box-shadow: none;
}

.search-results-datatable .p-datatable-thead{
    display: none;
}

.instant-search-dialog .p-dialog-footer{
    padding: 1.5rem;
}

.search-main-input{
  margin: 8px;
  width: 350px;
  padding-left: 30px;
}`, "",{"version":3,"sources":["webpack://./src/css/InstantSearch.css"],"names":[],"mappings":"AAAA;;EAEE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".instant-search-dialog .p-dialog-header,\n.instant-search-dialog .p-dialog-content {\n  padding: 0;\n  overflow-x: hidden;\n}\n\n.instant-search-dialog-header {\n  display: flex;\n}\n\n.instant-search-dialog-header .instant-search-input {\n  width: 95%;\n  border: none;\n}\n\n.instant-search-dialog-header .instant-search-input:focus {\n  outline: none;\n  box-shadow: none;\n}\n\n.search-results-datatable .p-datatable-thead{\n    display: none;\n}\n\n.instant-search-dialog .p-dialog-footer{\n    padding: 1.5rem;\n}\n\n.search-main-input{\n  margin: 8px;\n  width: 350px;\n  padding-left: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
