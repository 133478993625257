/**
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primeflex/primeflex.css";
import "./App.css";
import Schools from "./pages/Schools";
import SchoolDetailPage from "./pages/Schools/SchoolDetail";
import SchoolsLandingPage from "./pages/Schools/SchoolsLandingPage";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Switch>
          <Route path={`/`} component={SchoolsLandingPage} exact />
          <Route path={`/schools/:id`} component={SchoolDetailPage} exact />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default App;
