import React, { useRef } from "react";
import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { RadioButton } from "primereact/radiobutton";
import { Divider } from "primereact/divider";
import "../../css/FilterButton.css";

const FilterButton = () => {
  const op = useRef(null);
  const [sortBy, setSortBy] = React.useState("Default");
  const [userFilter, setUserFilter] = React.useState("All");

  return (
    <div>
      <Button
        label="Filter"
        icon="pi pi-filter-fill"
        className="p-button-outlined p-button-secondary p-button-sm filter-button"
        onClick={(e) => op.current.toggle(e)}
      />
      <OverlayPanel ref={op} dismissable className="filter-button-overlayPanel">
        <div style={{ padding: "10px", width: "200px" }}>
          <div>
            <strong>SORT BY:</strong>
            <div className="field-radiobutton custom-radiobutton">
              <label htmlFor="default">Default</label>
              <RadioButton
                inputId="default"
                name="sortBy"
                value="Default"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "Default"}
              />
            </div>
            <div className="field-radiobutton custom-radiobutton">
              <label htmlFor="firstName">First Name</label>
              <RadioButton
                inputId="firstName"
                name="sortBy"
                value="First Name"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "First Name"}
              />
            </div>
            <div className="field-radiobutton custom-radiobutton">
              <label htmlFor="lastName">Last Name</label>
              <RadioButton
                inputId="lastName"
                name="sortBy"
                value="Last Name"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "Last Name"}
              />
            </div>
            <div className="field-radiobutton custom-radiobutton">
              <label htmlFor="dueDate">Due Date</label>
              <RadioButton
                inputId="dueDate"
                name="sortBy"
                value="Due Date"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "Due Date"}
              />
            </div>
            <div className="field-radiobutton custom-radiobutton">
              <label htmlFor="lastLogin">Last Login</label>
              <RadioButton
                inputId="lastLogin"
                name="sortBy"
                value="Last Login"
                onChange={(e) => setSortBy(e.value)}
                checked={sortBy === "Last Login"}
              />
            </div>
          </div>

          <Divider style={{ height: "2px", background: "#d9d5ec" }} />

          <div>
            <strong>USERS:</strong>
            <div className="field-radiobutton custom-radiobutton">
              <label htmlFor="allUsers">All</label>
              <RadioButton
                inputId="allUsers"
                name="userFilter"
                value="All"
                onChange={(e) => setUserFilter(e.value)}
                checked={userFilter === "All"}
              />
            </div>
            <div className="field-radiobutton custom-radiobutton">
              <label htmlFor="activeUsers">Active</label>
              <RadioButton
                inputId="activeUsers"
                name="userFilter"
                value="Active"
                onChange={(e) => setUserFilter(e.value)}
                checked={userFilter === "Active"}
              />
            </div>
            <div className="field-radiobutton custom-radiobutton">
            <label htmlFor="inactiveUsers">Inactive</label>
              <RadioButton
                inputId="inactiveUsers"
                name="userFilter"
                value="Inactive"
                onChange={(e) => setUserFilter(e.value)}
                checked={userFilter === "Inactive"}
              />
              
            </div>
          </div>
        </div>
      </OverlayPanel>
    </div>
  );
};

export default FilterButton;
