/*
 *
 * Schools
 *
 */

import React from "react";
// import PropTypes from 'prop-types';
import Table from "../../components/Table";

const Schools = () => {
  // Example of setting breadcrumbs
  return (
    <div className="card">
      <Table></Table>
    </div>
  );
};

export default Schools;
