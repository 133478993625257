import React, { useEffect, useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Panel } from "primereact/panel";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { OverlayPanel } from "primereact/overlaypanel";
import { Dialog } from "primereact/dialog";
import "../../css/InstantSearch.css";
import { Button } from "primereact/button";

const InstantSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState(null);
  const [hoverSearch, setHoverSearch] = useState(null);

  const searchResults = [
    {
      id: "1",
      title: "No hits or is the search still in progress?",
      category: "Building Search UI",
    },
    {
      id: "2",
      title: "How will the data look?",
      category: "Building Search UI",
    },
    {
      id: "3",
      title: "Conditional display in InstantSearch.js",
      category: "Building Search UI",
    },
    {
      id: "4",
      title: "Accessing the state of other widgets",
      category: "Building Search UI",
    },
    // ... more results
  ];

  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (query) {
      const results = searchResults.filter((result) =>
        result.title.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  };

  const onShow = () => {
    setDisplayDialog(true);
  };

  const onHide = () => {
    setDisplayDialog(false);
  };

  const handleRowHover = (event) => {
    setHoverSearch(event.data);
  };

  const renderHeader = () => {
    return (
      <div className="instant-search-dialog-header">
        <InputText
          value={searchQuery}
          onChange={handleSearch}
          placeholder="Search..."
          className="instant-search-input"
        />
        <Button
          icon="pi pi-times"
          className="p-button-secondary p-button-text"
          aria-label="Cancel"
          onClick={() => setSearchQuery("")}
        />
        <Button
          className="p-button-secondary p-button-text"
          label="Cancel"
          onClick={onHide}
        />
      </div>
    );
  };

  const renderFooter = () => {
    return <div>Strapi</div>;
  };

  const renderSearchDetail = () => {
    return hoverSearch && <div>{hoverSearch.title}</div>;
  };

  return (
    <div className="p-grid p-align-center">
      <div className="p-col">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            className="p-inputtext-sm search-main-input"
            placeholder="Search..."
            onClick={onShow}
          />
        </span>
        <Dialog
          className="instant-search-dialog"
          header={renderHeader}
          footer={renderFooter}
          visible={displayDialog}
          closable={false}
          style={{ width: "50vw" }}
          onHide={onHide}
          position="top"
          draggable={false}
        >
          {searchQuery.length > 0 && (
            <div className="p-grid">
              <div className="p-col-6">
                <DataTable
                  value={filteredResults}
                  dataKey="id"
                  className="search-results-datatable"
                  selectionMode="single"
                  selection={selectedSearch}
                  onSelectionChange={(e) => setSelectedSearch(e.value)}
                  onRowMouseEnter={handleRowHover}
                >
                  <Column field="title" header="Title" />
                  <Column field="category" header="Category" />
                </DataTable>
              </div>
              <div className="p-col-6">{renderSearchDetail()}</div>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default InstantSearch;
