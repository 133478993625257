import React, { useEffect, useState } from "react";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";

import "../../css/SchoolDetail.css";

const SchoolDetailPage = () => {
  const [schoolData, setSchoolData] = useState({
    about:
      "<p>From humble beginnings, and through a series of name changes that led to a 1958 vote of the people and a victory for the advancement of Arizona State College to Arizona State University (ASU), ASU's century-plus trajectory has led to its being recognized as the most innovative university in the country by U.S. News & World Report - six years in a row. The educational experience at ASU is an immersion in a living- learning academic enterprise - a mindset focused on innovation and entrepreneurship, and a deep commitment to serving the public interest during a time of rapid and complex societal change. Nationally and internationally acclaimed, ASU consistently ranks among the very best in nearly every critical measurement of student success, outcomes of groundbreaking research and impact in the communities it serves.</p><br/> <p>Located in northwest Phoenix, ASU's West campus is home to students pursuing degrees in the health industry, business, education, and interdisciplinary arts and science programs. The West campus of ASU creates a learning community blending liberal arts education with 21st-century workforce preparation. This combination is exemplified by the New College of Interdisciplinary Arts and Sciences, and degree programs offered by the W. P. Carey School of Business, the College of Nursing and Health Innovation, Mary Lou Fulton Teachers College and the College of Health Solutions. The West campus is home to the first undergraduate degree to be offered by Thunderbird School of Global Management and a noted BS in Forensic Science through New College.</p>",
  });

  const renderAbout = (data) => {
    return <div dangerouslySetInnerHTML={{ __html: data }} />;
  };

  const renderCostAndDurationItem = (name, title) => {
    return (
      <div className="cost-and-duration-item">
        <i className="pi pi-th-large"></i>
        <div className="cost-and-duration-item-info">
          <div className="cost-and-duration-item-name">{name}</div>
          <div className="cost-and-duration-item-value">{title}</div>
        </div>
      </div>
    );
  };

  const renderGoogleMap = (data) => {
    return (
      // <GoogleMap
      //   mapContainerStyle={mapContainerStyle}
      //   center={center}
      //   zoom={10}
      // >
      //   <Marker position={center} />
      // </GoogleMap>
      <iframe
        // style="border:0;"
        loading="lazy"
        // referrerpolicy="no-referrer-when-downgrade"
        src="https://www.google.com/maps/embed/v1/place?q=1600+Amphitheatre+Parkway,+Mountain+View,+CA"
      ></iframe>
    );
  };

  const renderSideDetailLine = (title, detail) => (
    <div className="school-detail-side-detail-line">
      <div>{title}</div>
      <div>{detail}</div>
    </div>
  );

  return (
    <div className="container">
      <div className="p-grid">
        <div className="p-col-12 p-md-8">
          <div className="card school-detail-card">
            <h3 className="school-detail-header">
              <i className="pi pi-home"></i>ABOUT
            </h3>
            <Card>
              <div>{renderAbout(schoolData.about)}</div>
              <Divider className="school-detail-about-divider" />
              <div style={{ textAlign: "center" }}>
                <Button
                  label="Create Application"
                  icon="pi pi-plus"
                  className="p-button-info"
                  onClick={() =>
                    (window.location.href =
                      "http://localhost:1337/admin/plugins/applications/create")
                  }
                />
              </div>
            </Card>
            <h3 className="school-detail-header">
              <i className="pi pi-info-circle"></i>COST AND DURATION
            </h3>
            <Card>
              <div className="p-grid">
                <div className="p-col-4 cost-and-duration-col">
                  {renderCostAndDurationItem("$0.000", "Application fee")}
                  {renderCostAndDurationItem(
                    "1 Year",
                    "Average graduate program"
                  )}
                </div>
                <div className="p-col-4 cost-and-duration-col">
                  {renderCostAndDurationItem(
                    "3 Year",
                    "Average undergraduate program"
                  )}
                  {renderCostAndDurationItem(
                    "$12,000 / Year",
                    "Cost of living"
                  )}
                </div>
                <div className="p-col-4 cost-and-duration-col">
                  <div>
                    {renderCostAndDurationItem(
                      "$18,000 / Year",
                      "Gross tuition"
                    )}
                  </div>
                </div>
              </div>
            </Card>
            <h3 className="school-detail-header">
              <i className="pi pi-times"></i>LOCATION
            </h3>
            <Card>{renderGoogleMap(schoolData)}</Card>
          </div>
        </div>
        <div className="p-col-12 p-md-4">
          <div className="card school-detail-card">
            <div className="school-detail-side-header">Institution Detail</div>
            <Card>
              {renderSideDetailLine("Founded", "2010")}
              {renderSideDetailLine("School Id", "1994")}
              {renderSideDetailLine("Provider Id Number", "10001726")}
              {renderSideDetailLine("Institution type", "Public")}
            </Card>
          </div>
          <div className="card school-detail-card">
            <div className="school-detail-side-header">
              Average Time to Receive Letter of Acceptance
            </div>
            <Card>
              {renderSideDetailLine("January - April", "Under 31 days")}
              {renderSideDetailLine("May - August Id", "31+ days")}
              {renderSideDetailLine("Septemper - December", "15 - 30 days")}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolDetailPage;
